import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Create rich text editor which uses ReactQuill
const RichTextEditor = (props) => {
    const { id, setProps, value, style } = props;
    const [text, setText] = useState(value);

    useEffect(() => {
        setText(value);
    }, [value]);

    useEffect(() => {
        if (setProps) {
            setProps({ value: text });
        }
    }, [text, setProps]);

    return (
        <div id={id} style={style}>
            <ReactQuill
                value={text}
                onChange={setText}
                theme='snow'
            />
        </div>
    );
};

RichTextEditor.defaultProps = {
    style: {} // Default to an empty object if no style is provided
};

RichTextEditor.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * The value displayed in the input.
     */
    value: PropTypes.string,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func,

    /**
     * Optional style object to apply inline styles to the editor container.
     */
    style: PropTypes.object
};

export default RichTextEditor;
